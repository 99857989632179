<template>
    <div>
      <router-view v-slot="{ Component }" class="container-fluid page-content" :key="$route.fullPath">
        <transition>
          <keep-alive>
            <component :is="Component" />
          </keep-alive>
        </transition>
      </router-view>
    </div>
</template>>