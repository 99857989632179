import  Vuex from 'vuex'
export default Vuex.createStore({
    state() {
        return{
            minutes: 0,
            seconds: 0,
            maxMinutes: 14,
            
        }
    },
    getters: {
        getSec(state){
            return state.seconds
        },
        getMin(state){
            return state.minutes
        },
        getTimeLimit(state){
            return state.maxMinutes
        }
    },
    actions: {
        startClock(){
            console.log("Dispatched start clock")
            
            setTimeout(console.log("hello"), 1000);
            
        }
    },
    mutations: {
        
    }
})