<template>
  <div v-if="this.wait" class="spinnerLoader">
    <div class="spinner-border text-secondary" role="status">
    </div>
  </div>
  <div v-else class="main-div d-flex justify-content-center align-items-center">
    <!-- Chapter name is displayed -->
    <div class="app2 p-4">
      <div class="row">
        <h4>
          <b>
            {{ this.list[0].ChapterName }}
            <div>
              {{this.engTemp.exercise}} {{this.engTemp.colen}} {{ this.list[0].ChapterNo }}.{{ this.list[0].ExerciseNo }}
            </div>
          </b>
        </h4>
      </div>
      <hr>
      <div class="qsn-div"></div>
      <!-- Display Question -->
      <div v-if="!displayResult" class="question-section row">
        <div class="question-count row justify-content-between align-items-center">
          <div class="col-sm-8 row" style="padding-left: 24px !important">
            <div class="col-sm-6 question">
              <h3>{{this.engTemp.question}}{{this.engTemp.colen}}</h3>
            </div>
            <div class="col-sm-1">
              <span style="color: rgb(100, 44, 144);"> {{ currentIndex + this.list[0].FirstQuestionNumber }}</span>/{{
                this.list[0].QuestionCount + this.list[0].FirstQuestionNumber - 1
              }}
            </div>
            <div class="col-sm-1"></div>
          </div>
          <!-- <div class="col-sm-2"></div> -->
          <div class="col-sm-3" style="color: rgb(100, 44, 144);">
            <h4>{{ filterTime(this.minute) }}:{{ filterTime(this.sec) }}</h4>

          </div>
        </div>
        <!-- Question image is displayed -->
        <div v-for="(image, index) in this.list[0].QuestionCount" :key="index">
          <div class="centerDiv ">
            <!-- <div class="question-text m-2"> -->
            <div class="question-text ">
              <img v-show="currentIndex == index"
                :src='`https://dishaquizapp.s3.us-east-1.amazonaws.com/${this.list[0].ExerciseCode}/Q%20%28${index + 1}%29.png`'
                alt="image.alt" style="margin: 0.5rem !important">
            </div>
          </div>
          <div v-if="currentIndex === index" class="centerDiv mt-4 mb-2">
            <b>{{this.engTemp.correctAnsBelow}}</b>
          </div>
          <div v-if="currentIndex === index" class="row d-flex justify-content-end align-items-center">
            <button class="btn1 col" :class="{ active: this.optionA === this.selectedAns[index] }"
              @click="selectAns(this.optionA, index)">{{ this.optionA }}</button>
            <button class="btn1 col" :class="{ active: this.optionB === this.selectedAns[index] }"
              @click="selectAns(this.optionB, index)">{{ this.optionB }}</button>
            <button class="btn1 col" :class="{ active: this.optionC === this.selectedAns[index] }"
              @click="selectAns(this.optionC, index)">{{ this.optionC }}</button>
            <button class="btn1 col" :class="{ active: this.optionD === this.selectedAns[index] }"
              @click="selectAns(this.optionD, index)">{{ this.optionD }}</button>
          </div>
        </div>
        <button v-if="displayNext" class="btn btn-primary btn-lg btn-block mt-4 bttn"
          @click="nextQuestion">{{this.engTemp.next}}</button>
        <button v-else class="btn btn-primary btn-lg btn-block mt-4 bttn" id="submit" @click="submit">{{this.engTemp.submit}}</button>
        <button class="btn btn-primary btn-lg btn-block mt-2 bttn" @click="prevQuestion">{{this.engTemp.prev}}</button>

      </div>
      <!-- display result -->
      <div v-else class="result score-section d-flex flex-column">
        <div>
          <b>{{this.engTemp.totalQuestion}}</b>{{this.engTemp.equal}}{{ this.list[0].QuestionCount }} <br>
          <b> {{this.engTemp.correctAns}} </b> {{this.engTemp.equal}}{{ this.correctAns }} <br>
          <b>{{this.engTemp.timeTaken}} </b>{{this.engTemp.equal}}{{ minute }} {{this.engTemp.minutes}} {{ sec }} {{this.engTemp.seconds}}
        </div>
        <div v-for="(image, index) in this.list[0].QuestionCount" :key="index">
          <div class="solution-div p-4" style="color: rgb(100, 44, 144);">
            <b>{{this.engTemp.questionNo}}{{this.hinTemp.dot}}</b> {{this.engTemp.hyphen}} {{ index + 1 }}<br>
            <b>{{this.engTemp.selectedOption}}</b> {{this.engTemp.hyphen}} ({{ this.selectedAns[index] }}) <br>
            <b>{{this.engTemp.correctOption}} </b>{{this.engTemp.hyphen}}({{ this.answers[index] }}) <br>
            <div>

              <img
                :src='`https://dishaquizapp.s3.us-east-1.amazonaws.com/${this.list[0].ExerciseCode}/S%20%28${index + 1}%29.png`'
                style="width: 80%">
            </div>
            <hr />
          </div>
        </div>
      </div>

    </div>

  </div>
</template>
<script>
import template from "../../dummy.json";
import axios from 'axios';
export default {
  name: 'qNo1',
  data() {
    return {
      clockStarted: false,
      sec: 0,
      minute: 0,
      timeLimit: 14,
      optionA: "a",
      optionB: "b",
      optionC: "c",
      optionD: "d",
      selectedAns: [],
      displayResult: false,
      correctAns: 0,
      currentIndex: 0,
      displayNext: true,
      timer: null,
      list: undefined,
      answers: [],
      wait: true,
      engTemp: template["english"]

    }
  },

  created() {
    console.log(this.engTemp.exercise)
    console.log("welcome to single language")
    
    axios.get(`https://disha.sldev.in/${this.$route.params.id}`)
      // axios.get('https://disha.sldev.in/blcc7e1')
      .then((response) => {
        this.wait = false
        this.list = response.data
        this.minute = 0
        this.clock()
        this.answers = Array.from(this.list[0]["Answers"])
        this.clockStarted = true
      })
    if (localStorage.getItem("answered") === null) {
      localStorage.setItem("answered", JSON.stringify(this.selectedAns))
    }
    else {
      this.selectedAns = JSON.parse(localStorage.getItem("answered"))
    }
    if (localStorage.getItem("clockStarted") === null) {
      localStorage.setItem("clockStarted", JSON.stringify(this.clockStarted))
      localStorage.setItem("secUsed", JSON.stringify(this.sec))
      localStorage.setItem("minuteUsed", JSON.stringify(this.minute))
    }
    else {
      this.sec = JSON.parse(localStorage.getItem("secUsed"))
      this.minute = JSON.parse(localStorage.getItem("minuteUsed"))
    }
    // console.log(window.location.href)
  },
  methods: {
    filterTime(value) {
      
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString()
    },
    stopclock() {
      localStorage.removeItem("secUsed")
      localStorage.removeItem("minuteUsed")
      this.displayResult = true
      clearTimeout(this.timer)
    },
    clock() {
      this.timer = setTimeout(() => {
        if (this.timeLimit === this.minute) {
          alert("time up")
          this.stopclock()
        } else {
          this.sec++
          if (this.sec >= 60) {
            this.sec = 0
            this.minute++;
          }
          this.clock()
        }
      }, 1000)
    },
    selectAns(option, index) {
      if (this.selectedAns[index] !== null) {
        if (this.selectedAns[index] !== option) {
          this.selectedAns[index] = option;
        }
      } else {
        console.log(option);
        if (option === null || option === '' || option === undefined) {
          console.log(option);
          this.selectedAns.push("Skip");
        } else {
          this.selectedAns.push(option);
        }
      }
      localStorage.setItem("answered", JSON.stringify(this.selectedAns));
      localStorage.setItem("secUsed", JSON.stringify(this.sec))
      localStorage.setItem("minuteUsed", JSON.stringify(this.minute))
    },
    checkAns() {
      this.selectedAns.forEach((element, index) => {
        if (element === this.answers[index]) {
          this.correctAns++
        }
      });
    },
    submit() {
      console.log(this.selectedAns)
      this.stopclock()
      this.checkAns()
      this.displayResult = true
      localStorage.removeItem("answered")
      localStorage.removeItem("clockStarted")
      localStorage.removeItem("secUsed")
      localStorage.removeItem("minuteUsed")

    },
    nextQuestion() {
      if (this.selectedAns[this.currentIndex] === undefined) {
        this.selectedAns[this.currentIndex] = 'Skip';
      }
      if (this.currentIndex + 1 <= this.list[0].QuestionCount - 1) {
        this.currentIndex++
      }
      else {
        this.displayNext = false
        console.log("disabled")
      }
      localStorage.setItem("answered", JSON.stringify(this.selectedAns));
      localStorage.setItem("secUsed", JSON.stringify(this.sec))
      localStorage.setItem("minuteUsed", JSON.stringify(this.minute))
    },
    prevQuestion() {
      console.log(this.currentIndex)
      this.currentIndex--
      this.displayNext = true
      if (this.currentIndex < 0) {
        this.currentIndex = 0
      }
    },
  }
}
</script>
<style>
.question {
  padding: 0px 0px;
}

.answer-section {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.row {
  padding: 0px !important;
}

.col {
  padding: 0px !important;
}

.app2 {
  background-color: #fff;
  width: 540px;
  background: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 35%);
  border-radius: 15px;
}

.main-div {
  min-height: 80vh;
  width: 100%;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.d-flex {
  display: flex !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

* {
  font-family: "Verdana", cursive, sans-serif;
}

.qsn-div {
  float: right;
}

.question-section {
  width: 100%;
  position: relative;
}

.question-count span {
  font-size: 28px;
}

h4 {
  display: block;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.h4,
h4 {
  font-size: calc(1.275rem + .3vw);
}

.question-text img {
  width: 100%;
  height: auto;
}

img,
svg {
  vertical-align: middle;
}

img {
  overflow-clip-margin: content-box;
  overflow: clip;
}

.centerDiv {
  display: block;
  text-align: -webkit-center;
}

button {
  appearance: auto;
  writing-mode: horizontal-tb !important;
  text-rendering: auto;
  color: buttontext;
  letter-spacing: normal;
  word-spacing: normal;
  line-height: normal;
  text-transform: none;
  text-indent: 0px;
  text-shadow: none;
  display: inline-block;
  text-align: center;
  align-items: flex-start;
  cursor: default;
  box-sizing: border-box;
  background-color: buttonface;
  margin: 0em;
  padding: 1px 6px;
  border-width: 2px;
  border-style: outset;
  border-color: buttonborder;
  border-image: initial;
}

.btncolorchangeRight {
  color: #fff;
  background-color: #642c90;
  box-sizing: border-box;
  border-radius: 5px;
  width: 20%;
  height: 55px;
  font-size: large;
}


[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
  cursor: pointer;
}

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button;
}

button,
select {
  text-transform: none;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}


.btn1 {
  border: 1px solid #642c90;
  box-sizing: border-box;
  border-radius: 5px;
  width: 20%;
  background-color: #fff;
  height: 55px;
  color: #642c90;
  font-size: large;
}

.btn1:hover {
  background-color: #642c90;
  color: white;
}

.active {
  background-color: #642c90;
  color: white;
}

.bttn {
  background-color: #642c90;
  border-color: #050505;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: #642c90;
  border-color: #020202;
  opacity: var(--bs-btn-disabled-opacity);
}

.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: #642c90;
  border-color: #030303;
  --bs-btn-active-bg: #642C80;
  --bs-btn-hover-bg: #642c80;
  --bs-btn-hover-border-color: #030303;
  --bs-btn-active-border-color: #030303;
  --bs-btn-disabled-bg: #642c80;
  --bs-btn-disabled-border-color: #030303;
}
</style>