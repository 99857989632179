<template>
  <div class="app1">
    <navBar />
    <div>

    </div>
    <div>
      <div v-if="this.loadSpinner" class="spinnerLoader">
        <div class="spinner-border text-secondary" role="status">

        </div>
      </div>
      <div v-else-if="this.showAlert" class="alert">
      </div>
      <div v-else>
        <div v-if="isBilangual()">
          <blTest/>
        </div>
        <div v-else>
          <qNo1 />
        </div>
        

      </div>
    </div>


  </div>
</template>

<script>
import navBar from '../components/navBar.vue'
import qNo1 from '../components/qNo1.vue'
import blTest from '../components/blTest.vue'
export default {
  name: 'VimeoVideo',
  data() {
    return {
      showAlert: true,
      alertMessage: "your test will be of 14 minutes",
      loadSpinner: true,
      url : "",
      apiCode: ""
    }
  },
  components: {
    navBar,
    qNo1,
    blTest,
  },
  created() {
    setTimeout(()=>{
      this.loadSpinner = false
      this.apiCode = this.$route.params.id
      console.log(this.apiCode)
      setTimeout(() => {
        this.startTest()        
    }, 1)
    },2000)  
  },

  methods: {
    startTest() {
      // console.log(this.$route.params.id)
      alert("This test will be of 14 minutes", this.showAlert = false)
    },
    isBilangual(){
      const checkBl = this.apiCode.slice(0,2)
      if(checkBl === "bl") return true
      return false 
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url(/static/media/background.8191b549.svg);

}
.spinnerLoader{
  margin: 300px !important;


  display: flex;
  justify-content: center;
  align-Items: center;
}

.app1 {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

* {
  font-family: "Verdana", cursive, sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

div {
  display: block;
}
</style>
