// import qNo1 from './components/qNo1'
import Quiz from "./views/FoundationQuiz"
import Video from "./views/FoundationVideo"
import QuizPdf from "./views/FoundationQuizPdf"

import {createRouter, createWebHistory} from 'vue-router'

const routes =[
    {
        name: 'FoundationQuiz',
        component: Quiz,
        path:'/foundation/quiz-disha/:id',
        props: true
    },
    {
        name: 'FoundationVideo',
        component: Video,
        path:'/foundation/videos/:id',
        props: true
    },
    {
        name: 'FoundationQuizPdf',
        component: QuizPdf,
        path:'/foundation/quiz/:id',
        props: true
    }
]


const router = createRouter({
    history :createWebHistory(),
    routes
})
export default router