<template>
  <div class="app1">
    <!-- <navBar /> -->
    <div>
    </div>
    <div v-if="this.avVideoUrl">
      <iframe allowfullscreen="allowfullscreen" scrolling="no" class="fp-iframe" :src="`https://heyzine.com/api1?pdf=${avVideoUrl}&fs=1&pn=1&tpl=125f82ac36d74f8cb66b203f80bd6ba2740654d2.pdf&k=43f180395a7723f7`" style="border: 1px solid lightgray; width: 100%; max-height: 100vh; min-height: 100vh;"></iframe>
    </div>
  </div> 
</template>

<script>
// import navBar from '../components/navBar.vue'
import axios from 'axios';

export default {
  name: 'VimeoVideo',
  data() {
    return {
      showAlert: true,
      alertMessage: "your test will be of 14 minutes",
      loadSpinner: true,
      url: "",
      apiCode: "",
      avVideoUrl: null,
      videoId:""
    }
  },
  components: {
    // navBar,
  },
  created() {
    if(this.$route.params!=null)
    {
        this.videoId =this.$route.params.id;
        if(this.videoId !=null)
        {
          axios.get(`https://stg-foundation-apis.azurewebsites.net/api/get-foundation-quiz-data?videoId=${this.videoId}`)
            // axios.get('https://disha.sldev.in/blcc7e1')
            .then((response) => {
                const apiResponse = response.data;
                if(apiResponse!=null && apiResponse.length >0)
                {
                   // eslint-disable-next-line no-debugger
                   debugger;
                   this.avVideoUrl= apiResponse[0].PDFLink;
                }
            })
        }
    }
  },

  methods: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-image: url(/static/media/background.8191b549.svg);

}

.spinnerLoader {
  margin: 300px !important;


  display: flex;
  justify-content: center;
  align-Items: center;
}

.app1 {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}

* {
  font-family: "Verdana", cursive, sans-serif;
}

*,
::after,
::before {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

div {
  display: block;
}
</style>
