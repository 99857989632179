<template>
    <nav class="navbar navbar-light " style="background-color: rgb(100, 44, 144); height: 6vh;">
        <div class="container-fluid">
            <a class="navbar-brand" href="#"></a>
        </div>
    </nav>
</template>
<script>
    export default {
        name: 'navBar',
    }
</script>